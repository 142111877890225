/*GLOBAL*/
@import "common/variables";
@import "common/functions";
@import "common/fonts";
@import "common/normalize";
@import "common/elements";
@import "common/icons";
@import "common/global";
@import "common/links";
@import "common/buttons";
@import "common/display-property";
@import "common/form";
@import "common/new";

/*MENU*/
@import "menu/navbar";
@import "menu/menu";
@import "menu/doublemenu";

/*FILTERS*/
@import "filter/main-filter-wrapper";
@import "filter/selects";
@import "filter/sport-filter";
@import "filter/channel-filter";
@import "filter/side-filter";
@import "filter/detail-filter";

/*PAGES*/
@import "pages/contact";
@import "pages/channel";
@import "pages/clubs";
@import "pages/subscription";
@import "pages/channel-detail";

/*PARTIALS*/
@import "partials/image-circle";
@import "partials/cta-tile";
@import "partials/tile";
@import "partials/header-banner";
@import "partials/image-item";
@import "partials/partners";
@import "partials/slick";
@import "partials/slider-arrow";
@import "partials/section-header";
@import "partials/newsletter";
@import "partials/list-sports";
@import "partials/footer";
@import "partials/video-tile";
@import "partials/video-slider";
@import "partials/overlay";
@import "partials/breadcrumbs";
@import "partials/statistics";
@import "partials/video-tutorial";
@import "partials/channel-header";
@import "partials/subscription-option";
@import "partials/subscription-step";
@import "partials/substep";
@import "partials/channel-detail-result";
@import "partials/video-player";
@import "partials/playlist";
@import "partials/video-info";
@import "partials/match-results";
@import "partials/video-control";
@import "partials/info-overlay";
@import "partials/steps-section";
@import "partials/article";
@import "partials/social-sites";
@import "partials/error-page";
@import "partials/modal";
@import "partials/comments";

/*BLOG*/
@import "blog/blog-post-section";
@import "blog/post-item";
@import "blog/pagination";
@import "blog/blog-banner";
@import "blog/post-detail";

@import "player/canvas";
@import "player/player";

:root {
    color-scheme: light !important;
}